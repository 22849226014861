import styled from "styled-components";
// const primary = '#341744';
// const bg = '#432666';
export const secondary = "#ff8a23";
export const border = "#eee";
export const textColor = "#84817a";
export const borderDark = "#d1ccc0";
export const success = "#26de81";
export const error = "#eb4d4b";


// export const Top = styled.h1`
//  width: "100%",
//     height: "234px",
//     left:"800px",
//     bottom: "2966px",
//     background: "linear-gradient( 1.77deg,  #9134D8 0%, #7A6FFF 96.97%)
// `

export const PopUpContainer = styled.div`
  padding: var(--sm-font-size);
`;

export const ThemeButton = styled.button`
  border: none;
  border-radius: 100px;
  border: ${props => (props.primary ? "0" : "1px solid")};
  border-color: ${props => (props.primary ? "white" : `${secondary}`)};
  padding: 5px 20px;
  background: ${props => (props.primary ? `${secondary}` : "white")};
  color: ${props => (props.primary ? "white" : `${secondary}`)};
  font-size: 15px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s;
  &:hover {
    transform: ${props => props.primary && "translateY(-2px)"};
    box-shadow: ${props =>
      props.primary && "0px 3px 10px -2px rgba(0,0,0,0.5)"};
  }
`;

export const Title = styled.div`
  padding: 10px 0;
  font-size: var(--lg-font-size);
  color: rgba(0, 0, 0, 0.4);
  font-weight: 600;
  display: inline;
`;

export const SubHeading = styled.p`
  font-weight: 600;
  cursor: pointer;
  min-width: 90px;
  padding: ${props => (props.primary ? "4px 0px" : "0px")};
  width: ${props => (props.width ? props.width : "auto")};
  font-size: ${props =>
    props.primary ? "var(--lg-font-size)" : "var(--md-font-size)"};
  margin-bottom: ${props => (props.primary ? "0px" : "10px")};
`;
export const TagChipsContainer = styled.div`
  display: flex;
  padding: 10px 0px;
  flex-wrap: wrap;
`;
export const TagChips = ThemeButton.extend`
  border: 1px solid ${textColor};
  color: ${textColor};
  border: 2px solid var(--secondary-color);
  position: relative;
  padding: 5px 28px 5px 20px;
  margin: 4px 10px 4px 0px;
  text-transform: capitalize;
  font-size: var(--sm-font-size);
  transition: all 0.3s;
  background: rgba(255, 138, 35, 0.2);
  img {
    position: absolute;
    height: 100%;
    padding: 10px;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  &:hover {
    background: rgba(255, 138, 35, 0.9);
    color: #fff;
  }
`;

export const UploadContainer = styled.div`
  padding: 14px;
  width: 100%;
  background-color: #fafafa;
  border-radius: 10px;
  margin: 14px 0px;
`;

export const UploadButton = styled.button`
    background: none;
    outline: none;
    border: 0;
    cursor:pointer;
    font-size:var(--sm-font-size);
    color:${textColor}
    margin-right: 20px;
    img {
        height: 15px;
        margin-right: 5px;
    }
`;

export const SaveChangesButton = ThemeButton.extend`
margin-left:10px;
margin-right:${props => (props.marginRight ? props.marginRight : "0px")}
margin-bottom:8px;`;

export const McqSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: var(--lg-font-size);
  font-weight: 600;
  box-shadow: ${props =>
    props.shadow ? "0px 1px 3px 2px rgba(0,0,0,0.3)" : "0"};
`;

export const McqItemContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  width: ${props => (props.width ? props.width : "100%")};
  border-radius: 10px;
  position: relative;
  //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border: solid 1px rgba(0, 0, 0, 0.09);
  transition: all 0.3s ease-in;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 3px 12px -2px rgba(0, 0, 0, 0.5);
  }
`;

export const McqDescription = styled.div`
  font-size: 12px;
  line-height: 1.25;
  letter-spacing: 0.1px;
  text-align: justify;
  color: #000000;
  width: 85%;
`;
export const McqToolbar = styled.div`
  position: absolute;
  top: 0px;
  right: 18px;

  img {
    height: 22px;
    width: 22px;
    padding: 5px;
    background-repeat: no-repeat;
    cursor: pointer;
  }
`;
export const FlexBetween = styled.div`
  display: flex;
  justify-content: ${props => (props.start ? "flex-start" : "space-between")};
  align-items: center;
  border-bottom: ${props =>
    props.primary ? "1px solid rgba(71,73,162,0.1)" : 0};
`;

export const Heading = styled.p`
  font-size: var(--lg-font-size);
  font-weight: 600;
`;

export const AlignContentHorizontal = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "0px")};
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
`;

export const MainBodyContentContainer = styled.div`
  background-color: #ffffff;
  margin-top: ${props => (props.primary ? "0" : "10px")};
  position: relative;
  padding: 5px 10px;
  padding-bottom: 0;
  border-radius: 6px;
  min-height: ${props =>
    props.primary ? "calc(100vh - 110px)" : "calc(100vh - 150px)"};
`;

export const AutoContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
`;

export const ListContainer = styled.div`
  height: 100%;
  margin: 10px 0;
  padding-bottom: 60px;
  overflow-y: auto;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  // background-color: #432666;
  /* padding: 20px; */
`;

export const ContentContainer = styled.div`
  margin-left: 110px;
  width: 100%;
  overflow: hidden;
  padding: 0px 30px;
`;

export const PlusButton = styled.button`
  width: 56px;
  height: 56px;
  position: fixed;
  bottom: 20px;
  right: 25px;
  z-index: 1000;
  display: ${props => (props.hideBtn ? "none" : "flex")};
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  color: white;
  outline: none;
  cursor: pointer;
  border-radius: 100px;
  background-color: #ff8a23;
  border: solid 2px #ffffff;
  transition: all 0.3s;
  transform: ${props => (props.hide ? "scale(0)" : "scale(1)")};

  &:hover {
    animation: pulse 2s infinite;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 138, 35, 0.8);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(3, 168, 124, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(3, 168, 124, 0);
    }
  }
`;

export const PlusIcon = styled.img`
  width: 28px;
  height: 28px;
  text-align: center;
  transition: 0.3s;
  transform: ${props => (props.cross ? "rotate(45deg)" : "rotate(0deg)")};
`;

export const MainBodyContainer = styled.div`
  // margin-top: -20px;
  position: relative;
`;

export const AlbumContainer = styled.div`
  padding: 8px 0px;
  span {
    color: #000;
    font-weight: bold;
    font-size: var(--sm-font-size);
  }
`;

export const AlbumListContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
`;

export const AlbumItem = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  border: 0px;
  outline: none;
  border-radius: 4px;
  margin-right: 8px;
`;

export const AlbumImage = styled.img`
  max-width: 100%;
  height: 40px;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
`;

export const AudioList = styled.div`
  flex-flow: row wrap;
  width: 100%;
  display: flex;
  margin-top: -10px;
  padding: 4px 0px;
`;

export const AlbumLink = styled.a`
  max-width: 100%;
  height: 40px;
  border-radius: 4px;
  width: 40px;

  i {
    font-size: 28px;
  }
`;

export const LoadingImage = styled.img`
  width: ${props => props.width};
`;

export const LoadingContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  height: 100%;
  position: absolute;
  overflow: hidden;
  transition: ease-in 0.3s ease-out 0.3s;
  display: flex;
  z-index: 1000;
  font-size: 42px;
  font-size: 20px;
  font-weight: bold;
  color: #85c1e9;
  justify-content: center;
  align-items: center;
`;

export const LoaderImage = styled.img`
  width: 60px;
  height: 60px;
`;

export const McqOptionsToolbar = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  flex-flow: column wrap;
`;
export const McqOptionItem = styled.div`

    font-size:var(--xsm-font-size);
    margin-bottom:2px;
    cursor:pointer;
    color:${props => props.color};
    img{
        width:10px;
        height:10px;
        margin-right:8px;}
    i
    {
    margin-right:8px;
        color:${props => props.color};
`;

export const ErrorField = styled.span`
  width: ${props => (props.width ? props.width : "0")};
  font-size: 10px;
  color: red;
`;

export const ImageLoader = styled.div`
  z-index: 2;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 60px;
  padding-bottom: 60px;
  flex: 1;
  justify-content: center;
  align-items: center;
  h4 {
    opacity: 0.5;
    font-size: 32px;
  }
`;
export const DateSelectionField = styled.div`
  width: ${props => (props.width ? props.width : "140px")};
  height: 36px;
  border-radius: 20px;
  background-color: #ffffff;
  border: solid 1px #ededf6;
  display: flex;
  margin: 0px 6px;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    text-align: center;
  }

  input {
    border: none;
    font-size: var(--sm-font-size);
    margin-left: 4px;
    width: 100px;
    outline: none;
  }
`;

export const SelectorButton = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 100px;
  cursor: pointer;
  border: ${props =>
    props.active ? `1px solid ${secondary}` : `1px solid ${borderDark}`};
  display: flex;
  justify-content: center !important;
  align-items: center;
  div {
    width: 8px;
    height: 8px;
    border-radius: 100px;
    background: ${props => (props.active ? `${secondary}` : "#fff")};
  }
`;

export const SelectBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    margin-left: 6px;
    color: ${props => (props.active ? `${secondary}` : "rgba(0,0,0,0.6)")};
  }
`;

export const DropDown = styled.div`
  border: 1px solid ${borderDark};
  border-radius: 100px;
  position: relative;
  padding: 2px 10px;
  min-width: ${props => (props.minWidth ? props.minWidth : "120px")};
  cursor: pointer;
  outline: none;
  transition: all 0.3s;
`;

export const Chevron = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8px;
  height: 100%;
  position: absolute;
  transform: ${props => (props.cross ? props.cross : "rotate(0deg)")};
  right: 8px;
  top: 0;
  img {
    height: 8px;
    width: 8px;
  }
`;

export const DropDownMenu = styled.div`
  min-height: 25px;
  max-height: ${props => (props.maxHeight ? props.maxHeight : "250px")};
  background: white;
  overflow-x: hidden;
  overflow-y: auto;
  outline: none;
  z-index: 101;
  transition: all 0.3s;
  border: 1px solid ${borderDark};
  border-radius: 10px;
  position: absolute;
  left: 0;
  top: 30px;
  display: ${props => (props.active ? "block" : "none")};
  width: 100%;
  a {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: ${props => (props.color ? "6px 8px" : "2px 8px")};
    font-size: 14px;
    transition: all 0.3s;
    &:hover {
      color: ${props => (props.color ? "#000" : "white")} !important;
      text-decoration: none;
      background: ${props => (props.color ? "transparent" : "#ff8a23")};
    }
  }
  &::-webkit-scrollbar{
    display:block !important;
    width: 10px;
}
  }
`;
export const SubLevelMenu = styled.div`
  position: absolute;
  background: red;
  height: 100px;
  width: 150px;
  top: 0;
  right: 0;
`;

export const HoverAnimation = styled.div`
  transition: all 0.3s ease-in;
  &:hover {
    //border-radius: 5px;
    transform: translateY(-5px);
    box-shadow: 0px 3px 12px -2px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    border-bottom: 0;
  }
`;

export const PanelHeader = FlexBetween.extend`
  padding: 10px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: white;
  z-index: 20px;
  border-radius: ${props => (props.primary ? "10px 10px 0 0" : "0")};
`;

export const UploadImagesParentContainer = styled.div`
  width: 140px;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
`;

export const CheckBall = styled.div`
  height: 14px;
  width: 14px;
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  border: ${props =>
    props.active ? "1px solid #ff8a23" : "1px solid rgba(0,0,0,0.3)"};
  border-radius: ${props => (props.second ? "0" : "100%")};
`;

export const CheckBallInner = styled.div`
  height: 9px;
  width: 9px;
  border-radius: ${props => (props.second ? 0 : "100%")};
  background: ${props => (props.active ? `#ff8a23` : "white")};
`;

//..................experience flat card...........

export const FlatCard = HoverAnimation.extend`
  background: rgba(0, 0, 0, 0.2);
  background-image: url(${props =>
    props.bg ? require("static/img/Theme-loader.gif") : ""});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50px, cover;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border-radius: 10px;
  cursor: pointer;
  margin: 16px 0px;
  position: relative;
`;

export const FlatBackdrop = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  border-radius: 10px;
  background-image: url(${props => (props.bg ? `${props.bg}` : null)});
  width: 100%;
  height: 100%;
  &:after {
    position: absolute;
    border-radius: 10px;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
`;

export const FlexEnd = FlexBetween.extend`
  justify-content: flex-end;
  position: absolute;
  z-index: 100;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  top: 4px;
  right: 6px;
`;

export const FlexCenter = FlexBetween.extend`
  justify-content: center;
`;

export const FlexStart = FlexBetween.extend`
  justify-content: flex-start;
  align-items: center;
`;

export const BottomDetail = styled.div`
  display: flex;
  padding: 5px 0;
  max-height: 90px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  z-index: 100;
  left: 0;
  width: 100%;
  img {
    height: 35px;
    width: 35px;
    object-fit: cover;
    object-poisition: top;
    margin: 0 8px;
    border-radius: 100px;
    border: 1px solid white;
    align-self: flex-start;
  }
  div {
    color: white;
    p {
      margin: 0;
      font-weight: 600;
      height: ${props => (props.active ? "100%" : "")};
      align-items: center;
      display: flex;
    }
    div {
    }
  }
`;

export const IconContainer = styled.span`
padding:0 5px;
height:100%;
i{
font-size:${props => (props.size ? `${props.size}` : ``)};
a
{
text-decoration:none;
color:white;
}

`;

export const FilterDropDownMainContainer = styled.div`
  position: absolute;
  min-width: ${(props) => (props.minWidth ? props.minWidth : "120px")};
  height: ${(props) => (props.minHeight ? props.minHeight : "fit-content")};
  background: white;
  z-index: 1000;
  padding: 1rem;
  // top: ${(props) => (props.primary ? "30px" : "40px")};
  // right: ${(props) => (props.primary ? "0" : "100px")};
  -webkit-box-shadow: 6px 6px 12px 6px rgba(0, 0, 0, 0.175);
  box-shadow: 6px 6px 12px 6px rgba(0, 0, 0, 0.175);
  outline: none;
  right:0;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    outline: none;
    font-size: var(--sm-font-size);
    background: white;
  }
  ul li {
    cursor: pointer;
    width: 100%;
    padding: 6px;
    outline: none;
    display: block;
    border-bottom: 1px solid black;
    position: relative;

    &:last-child {
      border: none;
    }
  }

  ul li:hover {
    background-color: ${(props) => (props.primary ? "#fff" : "#ff8a23")};
    color: ${(props) => (props.primary ? "#000" : "white")};
    display: block;
  }
  li ul {
    display: none;
  }
  ul li ul li:hover {
    background: white;
  }

  ul li:active ul,
  ul li:hover ul {
    display: flex;
    max-height: 400px;
    flex-flow: column nowrap;
    top: 0;
    width: ${(props) => (props.width ? props.width : "120px")};
    padding: 0px;
    position: absolute;
    left: ${(props) => (props.width ? -props.width : "-120px")};
    background-color: transparent;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.175);
  }

  li ul li {
    border: none;
    width: 100%;
  }
`;

export const LevelDropdownItem = styled.div`
  display: flex;
  padding: 0px 6px;
  align-items: center;
  justify-content: space-between;
`;

export const SubLevelDropDown = FilterDropDownMainContainer.extend`
  left: 150px;
  top: ${props => props.top};
`;

export const EditorMainContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  background: white;
  position: relative;
  overflow-x: hidden;
  height: 100%;
  overflow-y: auto;
  padding: 14px;
`;

export const ToggleSlider = styled.div`
  width: 60px;
  height: 25px;
  border: 1px solid red;
  background: grey;
  border-radius: 4px;
  overflow: hidden;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    width: 70%;
    height: 24px;
    background: ${secondary};
    color: white;
    transition: all 0.3s;
    float: ${props => (props.active ? "right" : "left")};
  }
`;

export const ImageURLContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2px 10px;
  align-items: center;
  border-radius: 6px;
  color: var(--primary-color);
  border: dashed 1px rgba(179, 185, 194, 0.3);
  input {
    width: 80%;

    font-size: var(--md-font-size);
    border: none;
    outline: none;
    ::placeholder {
      font-size: var(--xsm-font-size);
      color: rgba(0, 0, 0, 0, 3);
      font-weight: 500;
    }
  }

  img {
    text-align: center;
    width: 16px;
    height: 14px;
    cursor: pointer;
    max-width: 100%;
  }
`;

export const AudioThemeButton = ThemeButton.extend`
  position: relative;
  cursor: pointer;
  input {
    position: absolute;
    top: 0;
    opacity: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
`;
export const AudioThemeUploadIcon = styled.div`
input{
  height: 0 !important;
  overflow: hidden !important;
  width: 0 !important;
  padding:0 !important;
  border: none;
}
label {
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
	font-size: inherit;
  font-weight: 500;
  outline: none;
  margin: 0;
  padding: 15px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  &.btn-2 {
    overflow: hidden;

    &::before {
      color: black;
      content: "\f093";
      font-family: "FontAwesome";
      font-size: 130%;
      height: 100%;
      left: 0;
      // line-height: 2;
      position: absolute;
      top: 0;
      transition: all 0.3s;
      width: 100%;
    }

    // &:hover {


    //   span {
    //     transform: translateY(300%);
    //   }

    //   &::before {
    //     top: 0;
    //   }
    // }
  }
}
`;

export const AudioThemeButton2 = styled.div`
input{
  height: 0;
  overflow: hidden;
  width: 0;
}
label {
  background: #ff8a23;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
	font-size: inherit;
  font-weight: 500;
  outline: none;
  margin: 30px 10px 25px 0;
  padding: 10px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  &.btn-2 {
    background-color: #ff8a23;
    border-radius: 50px;
    overflow: hidden;

    span {
      display: inline-block;
      height: 100%;
      transition: all 0.3s;
      width: 100%;
    }

    &::before {
      color: #fff;
      content: "\f093";
      font-family: "FontAwesome";
      font-size: 130%;
      height: 100%;
      left: 40%;
      line-height: 2;
      position: absolute;
      top: -180%;
      transition: all 0.3s;
      width: 100%;
    }

    &:hover {
      background-color: darken(#ee6d9e, 30%);

      span {
        transform: translateY(300%);
      }

      &::before {
        top: 0;
      }
    }
  }
}
`;

export const SubLevelItem = styled.a`
  backgroundcolor: #faf7ff;
  width: 100%;
  display: block;
  justify-content: flex-start;
  padding: 0px;
  padding-left: 24px;
`;

export const DropdownItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  background-color: #ffffff;
  flex-flow: column wrap;
`;

export const CheckBox = FlexCenter.extend`
  height: 15px;
  width: 15px;
  border-radius: 2px;
  transition: all 0.3s;
  background: ${props => (props.active ? `${secondary}` : "white")};
  border: ${props =>
    props.active ? `1px solid ${secondary}` : `1px solid ${borderDark}`};
  color: white;
  font-size: 10px;
`;

export const InputBox = styled.input`
  border-radius: 100px;
  padding: 0 10px;
  border: 1px solid ${borderDark};
  outline: none;
  text-align: center;
`;

//....................................Icon....................
export const CardIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const CardIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: ${props =>
    props.primary
      ? props.bg
        ? "#61bd4f"
        : "rgba(0,0,0,0.3)"
      : "rgba(0,0,0,0.3)"};
  border-radius: 100px;
  margin: 5px;
  transition: all 0.3s;
  color: white;
  &:hover {
    background: ${props =>
      props.primary ? (props.bg ? "#61bd4f" : "black") : "black"};
  }
`;

//...................................Image List......................
export const ImagesListContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin-top: ${props => (props.active ? "14px" : "0px")};
`;

export const PublishContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  span {
    font-size: 15px;
    font-weight: 600;
    color: #ff8a23;
    margin-left: 8px;
  }
`;

export const DraggableContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 240px);
  flex-flow: row wrap;
  overflow-y: auto;
`;

export const DraggableItem = styled.div`
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
`;

export const InfiniteScrollParentContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  top: ${props => (props.full ? "10px" : "58px")};
  ${props => (props.noTop ? "top: unset !important;":"")}
  padding-top: 10px;
  left: 0;
`;

export const InfiniteScrollLoader = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -14px;
`;

export const MediaThemedButton = styled.div`
  border: none;
  border-radius: 100px;
  border: ${props => (props.primary ? "0" : "1px solid")};
  border-color: ${props => (props.primary ? "white" : `${secondary}`)};
  padding: 5px 20px;
  background: ${props => (props.primary ? `${secondary}` : "white")};
  color: ${props => (props.primary ? "white" : `${secondary}`)};
  font-size: 15px;
  cursor: pointer;
  outline: none;
  position: relative;
  input {
    position: absolute;
    top: 0;
    opacity: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
`;

export const Chips = styled.span`
  border-radius: 100px;
  padding: 5px 10px;
  background: black;
  font-size: 13px;
`;

export const ComponentFooter = styled.div`
  position: absolute;
  bottom: 0;
  padding: 0 24px;
  left: 0;
  background: white;
  z-index: 1;
  //height:40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.3);
`;

export const NotificationScrollParentContainer = InfiniteScrollParentContainer.extend`
  position: relative;
  padding-top: 0;
  top: 0;
  max-height: calc(100vh - 190px);
`;

export const CountOverlayContainer = styled.div`
  right: 0;
  bottom: 0;
  height: ${props => (props.primary ? "50px" : "70px")};
  position: absolute;
  width: 40px;
  padding: 2px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px 0 10px 0;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const CountItem = styled.div`
  font-size: var(--xsm-font-size);
  padding: 3px 0px;
  width: 100%;
  color: white;
  i {
    color: white;
    font-size: 12px;
  }
  img {
    width: 14px;
    height: 14px;
  }
`;

export const DropdownFlexStart = FlexStart.extend`
     width:100%;
     padding:6px 8px;
     position:relative;
     font-size:${props => (props.second ? "14px" : "12px")};
     border-bottom: solid 1px #e6e6e6}
`;

export const ModalCountOverlayContainer = FlexBetween.extend`
  position: absolute;
  bottom: 20px;
  right: 55px;
  align-items: flex-end;
  width: ${props => (props.width ? props.width : "22%")};
`;

export const ModalCountItem = styled.div`
display:flex;
justify-content:flex-start;
align-items:center;
span{
color:#ff8a23;
font-size:20px;
}
//img
//{
//width:18px;
//height:18px;
//margin-left:8px;
//}
i {
font-size:16px;
margin-left:8px;
color:rgba(0,0,0,0.3);
`;

export const EmptyDataContainer = EmptyContainer.extend`
  i {
    font-size: 24px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    color: rgba(0, 0, 0, 0.6);
  }
  h3 {
    font-size: 28px;
  }
`;
