import styled from "styled-components";
import {TagChips} from "@sc/custom.styled";
import {
  NavigationContainer,
  NavigationHeaderContainer,
  NavigationToolbar,
} from "@sc/NavigationBar.styled";

export const FilterChips = TagChips.extend`
margin:0 5px;
font-size: 10px;
padding: 3px 28px 3px 20px;
`;


export const OptionContainer=styled.div`
 width:100%;
 display:flex;
 flex-flow:row wrap;
 justify-content:space-between;
 padding:0px 10px;
 align-items:center;
 color:black;


    `;

export const NavigationFilterToolbar = NavigationHeaderContainer.extend`
margin-bottom: 0.5rem;
padding: 0.5rem;
justify-content: flex-start;
`



