export const PRE_LIVE_CLASS = "pre-liveclass";
export const POST_LIVE_CLASS = "post-liveclass";


export const NEWS_FREAD =  "news";
export const ACTIVITY = "activity";
export const STORY = "story";
export const QUIZ =  "quiz";
export const WORD_GAME = "wordgame";
export const NEWS_FREAD_GLOBAL_SEARCH = "news_fread";

export const GRAMMAR = "GRAMMAR";
export const READING = "READING";
export const LISTENING = "LISTENING";
export const SPELLING = "SPELLING";

export const EASY = "easy";
export const MEDIUM = "medium";
export const HARD = "hard"

// export const WORD_GAME_NAME = "Word Game";
export const WORD_GAME_DESCRIPTION = "Difficulty level";
export const WORD_GAME_THUMBNAIL = "https://freadom-beta-bucket.s3.ap-south-1.amazonaws.com/adoption/word-game.jpeg";

export const ACADEMIC_YEAR_START = '04-01-2024'
export const ACADEMIC_YEAR_END = '2025-03-31'
